import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconScalability = ({
  title = 'IconScalability',
  viewBoxWidth = 52,
  viewBoxHeight = 52,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, ...otherProps }} fill="none">
    <path
      d="M14.9987 28V18.1333C14.9987 17.9212 14.9144 17.7177 14.7644 17.5676C14.6144 17.4176 14.4109 17.3333 14.1987 17.3333H4.33203M14.9987 28H5.13203C4.91986 28 4.71637 27.9157 4.56635 27.7657C4.41632 27.6157 4.33203 27.4122 4.33203 27.2V17.3333M14.9987 28H18.9987M4.33203 17.3333V13.3333M8.33203 4H5.13203C4.91986 4 4.71637 4.08429 4.56635 4.23431C4.41632 4.38434 4.33203 4.58783 4.33203 4.8V8M18.9987 4H13.6654M28.332 13.3333V18.6667M24.332 4H27.532C27.7442 4 27.9477 4.08429 28.0977 4.23431C28.2477 4.38434 28.332 4.58783 28.332 4.8V8M24.332 28H27.532C27.7442 28 27.9477 27.9157 28.0977 27.7657C28.2477 27.6157 28.332 27.4122 28.332 27.2V24M14.9987 13.3333H18.9987V17.3333"
      stroke="#323232"
      strokeWidth="2.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default IconScalability;
