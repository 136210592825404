import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';
import colors from 'styleguide/styles/colors';

const IconUpload = ({
  title = 'Upload',
  color = 'blue',
  viewBoxWidth = 46,
  viewBoxHeight = 42,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, color, ...otherProps }}>
    <path
      d="M15.038 13.901c.783 0 1.488-.334 1.973-.863l3.335-3.64v15.798c0 1.44 1.188 2.606 2.654 2.606s2.654-1.167 2.654-2.606V9.399l3.335 3.64c.485.53 1.19.862 1.973.862 1.465 0 2.653-1.167 2.653-2.606 0-.671-.258-1.282-.68-1.744L24.972.863A2.671 2.671 0 0 0 23 0c-.783 0-1.487.334-1.973.863L13.066 9.55a2.574 2.574 0 0 0-.681 1.744c0 1.44 1.188 2.606 2.653 2.606zm28.308 9.557c-1.466 0-2.654 1.167-2.654 2.607V36.49H5.308V26.065c0-1.44-1.188-2.607-2.654-2.607S0 24.625 0 26.065v13.032c0 1.44 1.188 2.606 2.654 2.606h40.692c1.466 0 2.654-1.167 2.654-2.606V26.065c0-1.44-1.188-2.607-2.654-2.607z"
      fill={colors[color]}
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default IconUpload;
