import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';
import colors from 'styleguide/styles/colors';

const IconFileCheck = ({
  title = 'Phone',
  color = 'dark',
  viewBoxWidth = 14,
  viewBoxHeight = 14,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, color, ...otherProps }}>
    <path
      d="M10.514 14a3.325 3.325 0 0 1-1.61-.413A21.839 21.839 0 0 1 .418 5.105 3.322 3.322 0 0 1 .974 1.14L1.781.33a1.12 1.12 0 0 1 1.653.072l1.529 1.808a1.898 1.898 0 0 1-.105 2.559l-.684.683a.413.413 0 0 0-.019.55c1.14 1.42 2.431 2.712 3.851 3.85.157.14.394.14.551 0l.684-.683a1.9 1.9 0 0 1 2.56-.105l1.809 1.529a1.12 1.12 0 0 1 .07 1.633l-.811.807a3.324 3.324 0 0 1-2.355.968zM2.579.71a.408.408 0 0 0-.29.118l-.812.811a2.624 2.624 0 0 0-.437 3.114 21.122 21.122 0 0 0 8.21 8.212 2.627 2.627 0 0 0 3.115-.437l.812-.811a.408.408 0 0 0-.028-.603l-1.81-1.533a1.187 1.187 0 0 0-1.595.066l-.683.679a1.126 1.126 0 0 1-1.496.08A25.366 25.366 0 0 1 3.59 6.435a1.125 1.125 0 0 1 .09-1.486l.69-.684c.43-.435.458-1.125.066-1.594L2.902.86a.404.404 0 0 0-.295-.147L2.58.709z"
      fill={colors[color]}
      fillRule="nonzero"
    />
  </SvgIcon>
);

export default IconFileCheck;
