import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconCloudSpeed = ({
  title = 'IconCloudSpeed',
  viewBoxWidth = 52,
  viewBoxHeight = 52,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, ...otherProps }} fill="none">
    <g clipPath="url(#clip0_6337_3124)">
      <path
        d="M13.7928 21.6749C14.344 22.2272 15.0516 22.4785 15.9156 22.4287C16.7797 22.3789 17.4096 22.0684 17.8055 21.4971L24.6361 11.9694C24.8791 11.6186 24.8584 11.3009 24.5739 11.0165C24.2894 10.732 23.9712 10.7107 23.6192 10.9525L14.0595 17.7511C13.4645 18.147 13.1332 18.7621 13.0657 19.5965C12.9981 20.4309 13.2405 21.1225 13.7928 21.6749ZM16.0009 3.55469C17.2727 3.55469 18.4556 3.67795 19.5496 3.92449C20.6436 4.16984 21.7139 4.5426 22.7605 5.04278C23.007 5.15182 23.2203 5.33079 23.4005 5.5797C23.5807 5.8286 23.6144 6.0621 23.5018 6.28018C23.3904 6.4959 23.1712 6.63813 22.844 6.70687C22.5169 6.77562 22.2259 6.75488 21.9711 6.64465C21.0466 6.19662 20.0984 5.86594 19.1265 5.65259C18.1557 5.43924 17.1139 5.33257 16.0009 5.33257C12.06 5.33257 8.70392 6.71754 5.9328 9.48748C3.16167 12.2574 1.7767 15.6135 1.77788 19.5556C1.77788 20.8002 1.94856 22.0299 2.28991 23.2447C2.63127 24.4596 3.11248 25.6004 3.73355 26.6672H28.2683C28.9499 25.5412 29.4465 24.3707 29.7582 23.1558C30.0699 21.941 30.2252 20.6816 30.224 19.3778C30.224 18.4711 30.1233 17.5051 29.9218 16.4799C29.7203 15.4535 29.3943 14.4816 28.9439 13.5642C28.8349 13.3117 28.8053 13.0628 28.855 12.8175C28.906 12.5709 29.0388 12.3742 29.2533 12.2272C29.4512 12.0933 29.6658 12.063 29.8969 12.1365C30.1292 12.2088 30.2999 12.3617 30.4089 12.5952C30.9897 13.7923 31.3986 14.9314 31.6357 16.0123C31.8727 17.0933 31.9948 18.208 32.0019 19.3565C32.009 20.8855 31.8389 22.3125 31.4916 23.6377C31.1456 24.9616 30.6063 26.2974 29.8738 27.645C29.7315 27.8821 29.5152 28.0747 29.2248 28.2228C28.9356 28.371 28.6168 28.4451 28.2683 28.4451H3.73355C3.40761 28.4451 3.10537 28.365 2.82683 28.205C2.54948 28.0462 2.31599 27.8139 2.12635 27.5081C1.53846 26.471 1.0371 25.3012 0.622259 23.9986C0.20742 22.696 0 21.215 0 19.5556C0 17.37 0.41484 15.3071 1.24452 13.3668C2.07301 11.4266 3.20493 9.7275 4.64028 8.26963C6.07443 6.81177 7.76935 5.66089 9.72502 4.81698C11.6842 3.97545 13.7762 3.55469 16.0009 3.55469Z"
        fill="#323232"
      />
    </g>
    <defs>
      <clipPath id="clip0_6337_3124">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default IconCloudSpeed;
