import * as React from 'react';
import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconThree = ({
  title = 'Three',
  color = 'mediumGray',
  viewBoxWidth = 73,
  viewBoxHeight = 104,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, color, viewBoxWidth, viewBoxHeight, ...otherProps }}>
    <path
      fill="#323232"
      d="M36.37 104c-6.974 0-13.173-1.219-18.597-3.658C12.382 97.904 8.12 94.51 4.988 90.16 2.047 86.075.402 81.394.052 76.117a.976.976 0 0 1 .983-1.033h16.232c.529 0 .963.413 1.028.938.292 2.347 1.15 4.423 2.578 6.229 1.614 2.01 3.761 3.575 6.44 4.696 2.68 1.12 5.683 1.68 9.009 1.68 3.551 0 6.699-.626 9.443-1.878 2.744-1.285 4.891-3.065 6.441-5.338 1.55-2.274 2.309-4.894 2.276-7.86.033-3.064-.742-5.767-2.324-8.106-1.582-2.34-3.875-4.169-6.877-5.487-2.97-1.318-6.554-1.977-10.751-1.977h-7.766a1 1 0 0 1-1-1V44.844a1 1 0 0 1 1-1h7.766c3.454 0 6.473-.61 9.056-1.829 2.615-1.22 4.665-2.933 6.15-5.14 1.485-2.241 2.212-4.828 2.18-7.76.032-2.868-.598-5.356-1.89-7.465-1.258-2.142-3.05-3.806-5.375-4.992-2.292-1.187-4.988-1.78-8.087-1.78-3.035 0-5.844.56-8.427 1.68-2.583 1.121-4.665 2.72-6.247 4.796-1.4 1.807-2.218 3.924-2.455 6.35-.053.537-.49.965-1.03.965h-15.3a.976.976 0 0 1-.983-1.022c.289-5.246 1.874-9.881 4.755-13.905 3.1-4.317 7.232-7.679 12.398-10.084C24.44 1.219 30.235 0 36.66 0c6.618 0 12.365 1.269 17.24 3.806 4.908 2.505 8.701 5.882 11.38 10.133 2.68 4.251 4.02 8.947 4.02 14.088.033 5.7-1.614 10.479-4.94 14.334-3.216 3.766-7.419 6.26-12.607 7.478a.479.479 0 0 0-.371.465c0 .236.174.435.407.47 6.775 1.033 11.983 3.65 15.623 7.85 3.745 4.25 5.601 9.54 5.569 15.866 0 5.668-1.582 10.743-4.746 15.225-3.132 4.448-7.458 7.941-12.979 10.479C49.77 102.731 43.473 104 36.37 104Z"
    />
    <path
      stroke="#323232"
      fill="#323232"
      d="M36.37 104c-6.974 0-13.173-1.219-18.597-3.658C12.382 97.904 8.12 94.51 4.988 90.16 2.047 86.075.402 81.394.052 76.117a.976.976 0 0 1 .983-1.033h16.232c.529 0 .963.413 1.028.938.292 2.347 1.15 4.423 2.578 6.229 1.614 2.01 3.761 3.575 6.44 4.696 2.68 1.12 5.683 1.68 9.009 1.68 3.551 0 6.699-.626 9.443-1.878 2.744-1.285 4.891-3.065 6.441-5.338 1.55-2.274 2.309-4.894 2.276-7.86.033-3.064-.742-5.767-2.324-8.106-1.582-2.34-3.875-4.169-6.877-5.487-2.97-1.318-6.554-1.977-10.751-1.977h-7.766a1 1 0 0 1-1-1V44.844a1 1 0 0 1 1-1h7.766c3.454 0 6.473-.61 9.056-1.829 2.615-1.22 4.665-2.933 6.15-5.14 1.485-2.241 2.212-4.828 2.18-7.76.032-2.868-.598-5.356-1.89-7.465-1.258-2.142-3.05-3.806-5.375-4.992-2.292-1.187-4.988-1.78-8.087-1.78-3.035 0-5.844.56-8.427 1.68-2.583 1.121-4.665 2.72-6.247 4.796-1.4 1.807-2.218 3.924-2.455 6.35-.053.537-.49.965-1.03.965h-15.3a.976.976 0 0 1-.983-1.022c.289-5.246 1.874-9.881 4.755-13.905 3.1-4.317 7.232-7.679 12.398-10.084C24.44 1.219 30.235 0 36.66 0c6.618 0 12.365 1.269 17.24 3.806 4.908 2.505 8.701 5.882 11.38 10.133 2.68 4.251 4.02 8.947 4.02 14.088.033 5.7-1.614 10.479-4.94 14.334-3.216 3.766-7.419 6.26-12.607 7.478a.479.479 0 0 0-.371.465c0 .236.174.435.407.47 6.775 1.033 11.983 3.65 15.623 7.85 3.745 4.25 5.601 9.54 5.569 15.866 0 5.668-1.582 10.743-4.746 15.225-3.132 4.448-7.458 7.941-12.979 10.479C49.77 102.731 43.473 104 36.37 104Z"
    />
  </SvgIcon>
);

export default IconThree;
