import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconNoCost = ({
  title = 'IconNoCost',
  viewBoxWidth = 52,
  viewBoxHeight = 52,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, ...otherProps }} fill="none">
    <path
      d="M2.66699 9.33464V20.0013C2.66699 20.7085 2.94794 21.3868 3.44804 21.8869C3.94814 22.387 4.62641 22.668 5.33366 22.668H6.66699M2.66699 9.33464V6.66797C2.66699 5.96072 2.94794 5.28245 3.44804 4.78235C3.94814 4.28225 4.62641 4.0013 5.33366 4.0013H8.00033M2.66699 9.33464C4.08148 9.33464 5.43803 8.77273 6.43823 7.77254C7.43842 6.77234 8.00033 5.41579 8.00033 4.0013M8.00033 4.0013H25.3337M13.019 16.0013C12.3378 15.2393 11.9742 14.2453 12.0027 13.2236C12.0313 12.2019 12.45 11.2298 13.1728 10.5071C13.8955 9.7843 14.8675 9.36565 15.8893 9.33706C16.911 9.30846 17.9049 9.6721 18.667 10.3533M2.66699 26.668L26.667 2.66797M28.2937 24.064L29.0257 15.716C29.1257 14.5626 28.2283 13.5426 27.0203 13.436L25.927 13.3413M23.555 27.856L25.7417 28.048C26.9497 28.1533 28.011 27.304 28.1123 26.152L28.295 24.064C25.8777 23.8533 23.7563 25.5506 23.555 27.856ZM23.555 27.856L9.33366 26.612M15.1297 21.976C15.675 22.6693 16.383 23.0226 17.3563 23.108C19.1683 23.2666 20.7603 21.9933 20.911 20.264C20.9923 19.336 20.7977 18.572 20.1763 17.94"
      stroke="#323232"
      strokeWidth="2.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default IconNoCost;
