import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';
import colors from 'styleguide/styles/colors';

const IconShipping = ({
  title = 'Shipping',
  color = 'dark',
  viewBoxWidth = 30,
  viewBoxHeight = 20,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, color, ...otherProps }}>
    <g stroke={colors[color]} fill="none" fillRule="evenodd">
      <path d="M21.09 1l-7.908 4.394v8.788l7.909 4.394L29 14.182V5.394L21.09 1z" />
      <path d="M13.182 5.394l7.909 4.394L29 5.394M21.09 9.788v8.788" />
      <path d="M17.343 7.643l7.602-4.561-7.602 4.56z" />
      <path
        fill={colors[color]}
        d="M.5 5.894h9.545v1H.5zM2.258 8.53h7.788v1H2.258zM4.015 11.167h6.03v1h-6.03z"
      />
    </g>
  </SvgIcon>
);

export default IconShipping;
