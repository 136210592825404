import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IconCart } from 'styleguide/icons';
import OrderContext from 'contexts/OrderContext/OrderContext';
import { preloadComponent } from 'app/bundles/App/DynamicPages.imports-loadable';

const CartIcon = () => {
  const orderContext = React.useContext(OrderContext);
  const order = orderContext.order;
  return (
    <RouterLink
      to="/cart"
      color="black"
      data-cy="cartLink"
      onMouseOver={() => preloadComponent('CartPage')}
      className="paragraph-mobile inline-flex cursor-pointer items-center bg-shades-0
  text-default no-underline transition duration-300 ease-in-out hover:text-gray-300"
    >
      <div className="flex-rows flex justify-start">
        <IconCart className="ml-1 cursor-pointer text-default" viewBox="0 -1 27 27" color="dark" />
        <div className="-ml-[6px] -mt-1 flex h-3 w-3 items-center justify-center rounded-full bg-blue text-[8px] text-shades-0">
          {order?.lineItems?.length || 0}
        </div>
      </div>
    </RouterLink>
  );
};

export default CartIcon;
