import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconPlatform = ({
  title = 'IconPlatform',
  viewBoxWidth = 52,
  viewBoxHeight = 52,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, ...otherProps }} fill="none">
    <path
      d="M12.5 21.5H4.5C3.96957 21.5 3.46086 21.2893 3.08579 20.9142C2.71071 20.5391 2.5 20.0304 2.5 19.5V5.5C2.5 4.96957 2.71071 4.46086 3.08579 4.08579C3.46086 3.71071 3.96957 3.5 4.5 3.5H20.5C21.0304 3.5 21.5391 3.71071 21.9142 4.08579C22.2893 4.46086 22.5 4.96957 22.5 5.5V12.5"
      stroke="#323232"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M2.5 7.501H22.5M5.5 5.511L5.51 5.5M8.5 5.511L8.51 5.5M11.5 5.511L11.51 5.5"
      stroke="#323232"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.5 14.5L19.725 15.544L21.328 15.672L21.456 17.275L22.5 18.5L21.456 19.725L21.328 21.328L19.725 21.456L18.5 22.5L17.275 21.456L15.672 21.328L15.544 19.725L14.5 18.5L15.544 17.275L15.672 15.672L17.275 15.544L18.5 14.5Z"
      stroke="#323232"
      strokeWidth="1.5"
    />
    <path
      d="M17.275 21.456L18.5 22.5V14.5L17.275 15.544L15.672 15.672L15.544 17.275L14.5 18.5L15.544 19.725L15.672 21.328L17.275 21.456Z"
      fill="#323232"
    />
  </SvgIcon>
);

export default IconPlatform;
