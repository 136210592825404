import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconFreeResources = ({
  title = 'IconFreeResources',
  viewBoxWidth = 52,
  viewBoxHeight = 52,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, ...otherProps }}>
    <path
      fill="#00AEEF"
      d="M10.4 3.862h1.733v1.734H10.4V3.862Zm3.467 0H15.6v1.734h-1.733V3.862Zm3.466 0h1.734v1.734h-1.734V3.862ZM.867 52.396h26.866a.867.867 0 0 0 .613-1.48L1.48 24.05a.867.867 0 0 0-1.48.613v26.866a.867.867 0 0 0 .867.867Zm.866-25.642 23.908 23.909H1.733V26.754Z"
    />
    <path
      fill="#00AEEF"
      d="M15.346 45.716 6.68 37.05a.867.867 0 0 0-1.48.613v8.666a.867.867 0 0 0 .867.867h8.667a.867.867 0 0 0 .612-1.48Zm-8.412-.254v-5.708l5.708 5.709H6.934ZM51.027 7.793a3.318 3.318 0 0 0-4.692-4.692l-1.3 1.3A4.325 4.325 0 0 0 40.733.396H11.266a4.34 4.34 0 0 0-4.333 4.333v22.533h1.733V4.73a2.6 2.6 0 0 1 2.6-2.6h29.467a2.6 2.6 0 0 1 2.6 2.6v1.375l-8.159 8.158h-6.37a9.53 9.53 0 1 0-9.738 12.957v3.51a.867.867 0 0 0 .867.867h19.066a.867.867 0 0 0 .867-.867V18.954l3.467-3.467V33.33H23.4a.867.867 0 0 0-.867.867v6.933a.867.867 0 0 0 .866.867h19.934v.866a2.6 2.6 0 0 1-2.6 2.6H26.866v1.733h13.867a4.341 4.341 0 0 0 4.333-4.333v-.867h6.067a.867.867 0 0 0 .866-.866v-6.933a.867.867 0 0 0-.866-.867h-6.067V13.754l5.961-5.96Zm-3.466-3.466a1.584 1.584 0 1 1 2.24 2.24l-.401.403-2.242-2.241.403-.402ZM27.733 17.729a7.808 7.808 0 0 1-6.933 7.749v-9.482h6.732c.132.568.2 1.15.201 1.733Zm-8.666-2.6v10.348a7.8 7.8 0 1 1 7.843-11.215h-6.977a.867.867 0 0 0-.866.866Zm19.066 14.733H20.8V27.22a9.493 9.493 0 0 0 8.501-11.223h4.14l-1.987 1.987a.859.859 0 0 0-.237.443l-.866 4.333a.866.866 0 0 0 1.022 1.02l4.334-.867a.859.859 0 0 0 .443-.237l1.983-1.987v9.174ZM32.6 20.353l1.177 1.177-1.474.294.297-1.47Zm14.2 14.709v1.733h1.733v-1.733h1.734v5.2h-26v-5.2H26v1.733h1.733v-1.733h1.734v1.733H31.2v-1.733h1.733v1.733h1.734v-1.733H36.4v1.733h1.733v-1.733h1.734v1.733H41.6v-1.733h1.733v1.733h1.734v-1.733H46.8ZM35.533 20.837l-2.24-2.242 12.64-12.641 2.242 2.241-12.642 12.642Z"
    />
  </SvgIcon>
);

export default IconFreeResources;
