import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconSlow = ({ title = 'IconSlow', viewBoxWidth = 52, viewBoxHeight = 52, ...otherProps }: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, ...otherProps }} fill="none">
    <g clipPath="url(#clip0_6337_3035)">
      <path
        d="M22.5 16.5C22.5 10.977 18.023 6.5 12.5 6.5C8.40003 6.5 4.87503 8.968 3.33203 12.5"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 17.5C2.76522 17.5 3.01957 17.3946 3.20711 17.2071C3.39464 17.0196 3.5 16.7652 3.5 16.5C3.5 16.2348 3.39464 15.9804 3.20711 15.7929C3.01957 15.6054 2.76522 15.5 2.5 15.5C2.23478 15.5 1.98043 15.6054 1.79289 15.7929C1.60536 15.9804 1.5 16.2348 1.5 16.5C1.5 16.7652 1.60536 17.0196 1.79289 17.2071C1.98043 17.3946 2.23478 17.5 2.5 17.5Z"
        fill="#666666"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 16.5H12.5"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 17.5C12.7652 17.5 13.0196 17.3946 13.2071 17.2071C13.3946 17.0196 13.5 16.7652 13.5 16.5C13.5 16.2348 13.3946 15.9804 13.2071 15.7929C13.0196 15.6054 12.7652 15.5 12.5 15.5C12.2348 15.5 11.9804 15.6054 11.7929 15.7929C11.6054 15.9804 11.5 16.2348 11.5 16.5C11.5 16.7652 11.6054 17.0196 11.7929 17.2071C11.9804 17.3946 12.2348 17.5 12.5 17.5Z"
        fill="#666666"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6337_3035">
        <rect width="24" height="24" fill="white" transform="translate(0.5 0.5)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default IconSlow;
