import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconChooseDesign = ({
  title = 'ChooseDesign',
  color = 'none',
  viewBoxWidth = 48,
  viewBoxHeight = 52,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, color, ...otherProps }}>
    <path
      fill="#F5A623"
      d="m32.48 14.899-4.95 28.076a4.38 4.38 0 0 1-5.07 3.55l-17.244-3.04a4.38 4.38 0 0 1-3.551-5.07l4.95-28.077a4.38 4.38 0 0 1 5.071-3.55l17.244 3.04a4.38 4.38 0 0 1 3.55 5.071Z"
    />
    <path
      fill="#D6D6D6"
      d="M36.741 10.896V42.97a4.927 4.927 0 0 1-4.924 4.924H12.119a4.927 4.927 0 0 1-4.925-4.924V10.896a4.927 4.927 0 0 1 4.925-4.924h19.698a4.927 4.927 0 0 1 4.924 4.924Z"
    />
    <path
      fill="#00AEEF"
      d="m40.242 5.388 6.189 35.095a5.474 5.474 0 0 1-4.439 6.339l-21.554 3.8a5.474 5.474 0 0 1-6.339-4.438L7.911 11.09a5.474 5.474 0 0 1 4.438-6.338L33.904.95a5.474 5.474 0 0 1 6.338 4.438Z"
    />
  </SvgIcon>
);

export default IconChooseDesign;
