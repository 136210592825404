import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconResolution = ({
  title = 'Resolution',
  color = 'none',
  viewBoxWidth = 46,
  viewBoxHeight = 46,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, color, ...otherProps }}>
    <g stroke="#323232" fill="none" fillRule="evenodd">
      <path
        fill="#00AEEF"
        d="M7.5 23.5h7v7h-7zM23.5 7.5h7v7h-7zM15.5 31.5h7v7h-7zM31.5 15.5h7v7h-7zM31.5 31.5h7v7h-7zM23.5 23.5h7v7h-7zM15.5 15.5h7v7h-7zM7.5 7.5h7v7h-7z"
      />
      <path d="M1 38v7h6M1 8V1h6M45 38v7h-6M45 8V1h-6" />
    </g>
  </SvgIcon>
);

export default IconResolution;
