import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconDrawnEllipse = ({
  title = 'DrawnEllipse',
  viewBoxWidth = 140,
  viewBoxHeight = 76,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, ...otherProps }} fill="none">
    <path
      d="M130.39 25.56C125.145.26 83.305 1.605 63.609 1.976c-17.925.338-38.166 4.516-50.336 18.553-9.6 11.071-18.008 27.633-7.21 40.868 10.024 12.286 25.77 13.305 40.528 13.43 21.007.178 42.393-2.38 62.426-8.824 9.622-3.094 29.194-5.696 29.946-18.781.998-17.342-18.562-28.672-30.975-36.542"
      stroke="#00AEEF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default IconDrawnEllipse;
