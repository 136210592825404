import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconFastTurnarounds = ({
  title = 'IconFastTurnarounds',
  viewBoxWidth = 68,
  viewBoxHeight = 52,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, ...otherProps }}>
    <path
      fill="#0194CB"
      d="M4.333 15.902H19.5a1.445 1.445 0 0 0 0-2.89H4.333a1.445 1.445 0 0 0 0 2.89Zm-2.889 7.694h15.168a1.445 1.445 0 0 0 0-2.889H1.444a1.445 1.445 0 0 0 0 2.89Zm16.613 6.252c0-.798-.647-1.445-1.444-1.445H1.445a1.445 1.445 0 0 0 0 2.89h15.168c.797 0 1.444-.647 1.444-1.445Zm2.889 7.694c0-.797-.647-1.444-1.444-1.444H4.334a1.445 1.445 0 0 0 0 2.889h15.168c.797 0 1.444-.647 1.444-1.445ZM44.747 4.2a1.64 1.64 0 0 0-1.64 1.64v8.403a1.64 1.64 0 0 0 3.28 0V7.552C55.835 8.385 63.269 16.34 63.269 26c0 10.213-8.308 18.52-18.52 18.52S26.227 36.212 26.227 26a18.597 18.597 0 0 1 7.613-14.969 1.641 1.641 0 0 0-1.935-2.65c-5.61 4.097-8.959 10.684-8.959 17.619 0 12.02 9.78 21.8 21.8 21.8 12.021 0 21.801-9.779 21.801-21.8s-9.78-21.8-21.8-21.8Z"
    />
    <path
      fill="#0194CB"
      d="M33.762 19.841a1.64 1.64 0 0 0 .592 2.243l9.157 5.334a1.639 1.639 0 1 0 1.651-2.834l-9.157-5.334a1.64 1.64 0 0 0-2.243.591Z"
    />
  </SvgIcon>
);

export default IconFastTurnarounds;
