import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconPriceTag = ({
  title = 'IconPriceTag',
  viewBoxWidth = 52,
  viewBoxHeight = 52,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, ...otherProps }}>
    <path
      d="M25.9894 52.0001H2.16586C0.974482 52.0001 0 51.0256 0 49.7258V23.6277C0 22.978 0.324843 22.3283 0.758096 21.8951L12.6699 11.3911C13.5364 10.633 14.619 10.633 15.4854 11.3911L27.3972 21.8951C27.8305 22.3284 28.1553 22.9781 28.1553 23.6277V49.7258C28.1553 51.0252 27.1808 51.9997 25.9895 51.9997L25.9894 52.0001ZM14.0777 13.0158L2.16586 23.5198V49.7265L25.9894 49.8345V23.6278L14.0777 13.0158Z"
      fill="#0194CB"
    />
    <path
      d="M14.0776 26.0104C11.6953 26.0104 9.74585 24.0613 9.74585 21.6786C9.74585 19.2964 11.6949 17.3469 14.0776 17.3469C16.4598 17.3469 18.4093 19.296 18.4093 21.6786C18.4093 24.0613 16.4598 26.0104 14.0776 26.0104ZM14.0776 19.5128C12.8862 19.5128 11.9117 20.4873 11.9117 21.6786C11.9117 22.87 12.8862 23.8445 14.0776 23.8445C15.269 23.8445 16.2434 22.87 16.2434 21.6786C16.2434 20.4877 15.2689 19.5128 14.0776 19.5128Z"
      fill="#0194CB"
    />
    <path
      d="M14.2938 21.6785H13.9689C13.3193 21.6785 12.886 21.1373 12.9944 20.4871C12.9944 19.9459 13.5357 19.4042 14.1858 19.5126C14.4022 19.5126 19.9253 19.729 24.1486 14.5312C24.7983 13.7731 24.6899 11.7157 24.6899 9.76659C24.5815 6.08455 24.4735 1.10354 29.1296 0.0205617C29.6709 -0.0878471 30.321 0.236987 30.429 0.887067C30.5374 1.53675 30.1045 2.07805 29.4545 2.18644C26.7473 2.83612 26.6389 5.54328 26.7473 9.76661C26.8557 12.2574 26.8557 14.5312 25.7728 15.939C21.4411 21.3537 15.81 21.6785 14.2934 21.6785L14.2938 21.6785Z"
      fill="#0194CB"
    />
  </SvgIcon>
);

export default IconPriceTag;
