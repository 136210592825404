import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconConvenience = ({
  title = 'IconConvenience',
  viewBoxWidth = 52,
  viewBoxHeight = 52,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, ...otherProps }} fill="none">
    <path
      d="M16.333 8V16H24.333"
      stroke="#323232"
      strokeWidth="2.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.5173 13.9974C28.552 7.58273 23.0173 2.66406 16.3333 2.66406C8.96933 2.66406 3 8.6334 3 15.9974C3 23.3614 8.96933 29.3307 16.3333 29.3307C21.8 29.3307 26.5 26.0401 28.5573 21.3307"
      stroke="#323232"
      strokeWidth="2.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23 21.3281H28.8667C28.9717 21.3281 29.0758 21.3488 29.1728 21.389C29.2699 21.4292 29.3581 21.4882 29.4324 21.5624C29.5066 21.6367 29.5656 21.7249 29.6058 21.822C29.646 21.919 29.6667 22.0231 29.6667 22.1281V27.9948"
      stroke="#323232"
      strokeWidth="2.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default IconConvenience;
