import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';
import colors from 'styleguide/styles/colors';

const IconTooltipQuestion = ({
  title = 'Tooltip question',
  color = 'dark',
  viewBoxWidth = 15,
  viewBoxHeight = 15,
  fill = 'none',
  ...otherProps
}: SvgProps) => (
  <SvgIcon
    className={fill !== 'none' ? 'fill-default' : 'fill-shades-0'}
    {...{ title, viewBoxWidth, viewBoxHeight, color, ...otherProps }}
  >
    <g transform="translate(1 -6)" fillRule="evenodd">
      <circle stroke={colors[color]} cx="6.5" cy="13.5" r="6.5" />
      <path
        d="M5.142 11.795c.026-.527.215-.975.568-1.343.354-.368.874-.552 1.56-.552.616 0 1.115.169 1.497.506.383.337.574.757.574 1.262 0 .384-.092.714-.276.989a2.322 2.322 0 0 1-.745.696c-.309.182-.519.355-.63.52-.11.164-.166.375-.166.632v.317H6.67v-.449c-.003-.322.075-.6.234-.835.16-.234.407-.45.742-.65.284-.172.486-.346.606-.522.12-.176.18-.394.18-.654a.977.977 0 0 0-.324-.752c-.217-.199-.507-.298-.872-.298-.368 0-.663.102-.886.305-.223.204-.347.48-.373.828h-.835zm2.021 5.293a.661.661 0 0 1-.488-.19.661.661 0 0 1-.19-.489c0-.202.063-.366.19-.493a.661.661 0 0 1 .488-.19c.199 0 .36.063.486.19a.672.672 0 0 1 .188.493.666.666 0 0 1-.188.488.653.653 0 0 1-.486.19z"
        fill={fill !== 'none' ? 'white' : colors[color]}
      />
    </g>
  </SvgIcon>
);

export default IconTooltipQuestion;
