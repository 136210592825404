import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconUnScalable = ({
  title = 'IconUnScalable',
  viewBoxWidth = 52,
  viewBoxHeight = 52,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, ...otherProps }} fill="none">
    <path
      d="M11.5 15.5V14.1C11.5 13.9409 11.4368 13.7883 11.3243 13.6757C11.2117 13.5632 11.0591 13.5 10.9 13.5H9.5M6.5 13.5H3.5M11.5 18.5V21.5"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.9 3.5L4.1 3.5C3.94087 3.5 3.78826 3.56321 3.67574 3.67574C3.56321 3.78826 3.5 3.94087 3.5 4.1L3.5 20.9C3.5 21.0591 3.56321 21.2117 3.67574 21.3243C3.78826 21.4368 3.94087 21.5 4.1 21.5H20.9C21.0591 21.5 21.2117 21.4368 21.3243 21.3243C21.4368 21.2117 21.5 21.0591 21.5 20.9V4.1C21.5 3.94087 21.4368 3.78826 21.3243 3.67574C21.2117 3.56321 21.0591 3.5 20.9 3.5Z"
      stroke="#666666"
      strokeWidth="1.499"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 11.5H13.5V8.5"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default IconUnScalable;
