import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconClockRotate = ({
  title = 'Clock',
  color = 'brightRed',
  viewBoxWidth = 52,
  viewBoxHeight = 52,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, color, viewBoxWidth, viewBoxHeight, ...otherProps }} fill="none">
    <path
      d="M12.5 6.5V12.5H18.5"
      stroke="#323232"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.388 11C21.664 6.189 17.513 2.5 12.5 2.5C6.977 2.5 2.5 6.977 2.5 12.5C2.5 18.023 6.977 22.5 12.5 22.5C16.6 22.5 20.125 20.032 21.668 16.5"
      stroke="#323232"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 16.5H21.9C21.9788 16.5 22.0568 16.5155 22.1296 16.5457C22.2024 16.5758 22.2685 16.62 22.3243 16.6757C22.38 16.7315 22.4242 16.7976 22.4543 16.8704C22.4845 16.9432 22.5 17.0212 22.5 17.1V21.5"
      stroke="#323232"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default IconClockRotate;
