import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';
import colors from 'styleguide/styles/colors';

const IconTooltipInfo = ({
  title = 'Tooltip info',
  color = 'dark',
  viewBoxWidth = 15,
  viewBoxHeight = 15,
  fill = 'none',
  ...otherProps
}: SvgProps) => (
  <SvgIcon
    className={fill !== 'none' ? 'fill-default' : 'fill-shades-0'}
    {...{ title, viewBoxWidth, viewBoxHeight, color, ...otherProps }}
  >
    <g transform="translate(1 -6)" fill="none" fillRule="evenodd">
      <circle stroke={colors[color]} cx="6.5" cy="13.5" r="6.5" />
      <path
        d="M6.228 17v-5.264h.84V17h-.84zm.42-6.333a.563.563 0 0 1-.413-.173.563.563 0 0 1-.173-.413c0-.163.057-.301.173-.415a.567.567 0 0 1 .412-.17c.163 0 .302.056.415.17.114.114.171.252.171.415 0 .16-.057.297-.17.413a.561.561 0 0 1-.416.173z"
        fill={fill !== 'none' ? 'white' : colors[color]}
      />
    </g>
  </SvgIcon>
);

export default IconTooltipInfo;
