import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconDesign = ({
  title = 'Design',
  color = 'none',
  viewBoxWidth = 64,
  viewBoxHeight = 51,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, color, ...otherProps }}>
    <path
      fill="#fff"
      d="M5.287 33.274H0v5.287h5.287v-5.287Zm58.713 0h-5.287v5.287H64v-5.287ZM34.643.988h-5.287v5.287h5.287V.988Z"
    />
    <path
      stroke="#fff"
      strokeMiterlimit="10"
      strokeWidth="1.465"
      d="M2.634 33.417S2.644 3.63 32 3.63c29.357 0 29.347 29.786 29.347 29.786"
    />
    <path
      stroke="#fff"
      strokeMiterlimit="10"
      strokeWidth="1.465"
      d="M31.995 3.631H32c29.357 0 29.347 29.786 29.347 29.786"
    />
    <path
      fill="#fff"
      d="M2.643 5.19a1.56 1.56 0 1 1 0-3.118 1.56 1.56 0 0 1 0 3.119Zm58.714 0a1.56 1.56 0 1 1 0-3.118 1.56 1.56 0 0 1 0 3.119Z"
    />
    <path stroke="#fff" strokeMiterlimit="10" strokeWidth="1.465" d="M2.644 3.631h58.713" />
    <path
      fill="#00AEEF"
      d="m44.811 36.344-6.79 8.7a.659.659 0 0 1-.52.254H26.556a.659.659 0 0 1-.519-.252l-6.808-8.704a1.62 1.62 0 0 1-.203-1.659l9.909-22.17a.47.47 0 0 1 .428-.278h1.236a.47.47 0 0 1 .469.47l.01 17.957a3.252 3.252 0 0 0-2.298 3.393 3.266 3.266 0 0 0 3.03 2.966 3.253 3.253 0 0 0 1.151-6.36l-.01-17.956c0-.26.21-.47.47-.47h1.192c.184 0 .352.109.428.277l9.972 22.17a1.62 1.62 0 0 1-.2 1.662ZM37.4 50.958H26.934a.54.54 0 0 1-.54-.54v-3.244a.54.54 0 0 1 .54-.54H37.4a.54.54 0 0 1 .54.54v3.244a.54.54 0 0 1-.54.54Z"
    />
  </SvgIcon>
);

export default IconDesign;
