import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconInConsistentQuality = ({
  title = 'IconInConsistentQuality',
  viewBoxWidth = 52,
  viewBoxHeight = 52,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, ...otherProps }} fill="none">
    <g clipPath="url(#clip0_6337_3043)">
      <path
        d="M5.5 3.5C5.76522 3.5 6.01957 3.39464 6.20711 3.20711C6.39464 3.01957 6.5 2.76522 6.5 2.5C6.5 2.23478 6.39464 1.98043 6.20711 1.79289C6.01957 1.60536 5.76522 1.5 5.5 1.5C5.23478 1.5 4.98043 1.60536 4.79289 1.79289C4.60536 1.98043 4.5 2.23478 4.5 2.5C4.5 2.76522 4.60536 3.01957 4.79289 3.20711C4.98043 3.39464 5.23478 3.5 5.5 3.5Z"
        fill="#666666"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 22.5H5.5V2.5"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 23.5C5.76522 23.5 6.01957 23.3946 6.20711 23.2071C6.39464 23.0196 6.5 22.7652 6.5 22.5C6.5 22.2348 6.39464 21.9804 6.20711 21.7929C6.01957 21.6054 5.76522 21.5 5.5 21.5C5.23478 21.5 4.98043 21.6054 4.79289 21.7929C4.60536 21.9804 4.5 22.2348 4.5 22.5C4.5 22.7652 4.60536 23.0196 4.79289 23.2071C4.98043 23.3946 5.23478 23.5 5.5 23.5Z"
        fill="#666666"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 4.693C9.87 3.321 11.608 2.5 13.5 2.5C17.918 2.5 21.5 6.977 21.5 12.5C21.5 15.771 20.244 18.675 18.3 20.5"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.7 20.5002C8.25441 20.0801 7.85237 19.6161 7.5 19.1152"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 23.5C13.7652 23.5 14.0196 23.3946 14.2071 23.2071C14.3946 23.0196 14.5 22.7652 14.5 22.5C14.5 22.2348 14.3946 21.9804 14.2071 21.7929C14.0196 21.6054 13.7652 21.5 13.5 21.5C13.2348 21.5 12.9804 21.6054 12.7929 21.7929C12.6054 21.9804 12.5 22.2348 12.5 22.5C12.5 22.7652 12.6054 23.0196 12.7929 23.2071C12.9804 23.3946 13.2348 23.5 13.5 23.5Z"
        fill="#666666"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6337_3043">
        <rect width="24" height="24" fill="white" transform="translate(0.5 0.5)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default IconInConsistentQuality;
