import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';
import colors from 'styleguide/styles/colors';

const IconExclamationMark = ({
  title = 'Adaptability',
  color = 'dark',
  viewBoxWidth = 23,
  viewBoxHeight = 20,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, ...otherProps }}>
    <g transform="translate(1)" fill="none" fillRule="evenodd">
      <text fontFamily="Montserrat-SemiBold, Montserrat" fontSize="14" fontWeight="500" fill={colors[color]}>
        <tspan x="8" y="14">
          !
        </tspan>
      </text>
      <circle stroke="#FFF" cx="10" cy="11" r="10" />
    </g>
  </SvgIcon>
);

export default IconExclamationMark;
