import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';
import colors from 'styleguide/styles/colors';

const IconArrowUp = ({
  title = 'ArrowUp',
  color = 'dark',
  viewBoxWidth = 20,
  viewBoxHeight = 12,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, color, ...otherProps }}>
    <path
      d="M0 11L10.243 1 20 10.526"
      stroke={colors[color]}
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default IconArrowUp;
