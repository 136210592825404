import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconDimension = ({
  title = 'Dimension',
  viewBoxWidth = 44,
  viewBoxHeight = 52,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, ...otherProps }}>
    <g fill="none" fillRule="evenodd">
      <path d="M13.333 13.333V49h28.334V13.333H13.333z" stroke="#323232" fill="#FFF" />
      <g fill="#00AEEF">
        <path d="M11 3.667h31.167V5.5H11zM42.167 0H44v9.167h-1.833z" />
        <path d="M11 0h1.833v9.167H11z" />
      </g>
      <g fill="#00AEEF">
        <path d="M5.5 11v38.5H3.667V11zM9.167 49.5v1.833H0V49.5z" />
        <path d="M9.167 11v1.833H0V11z" />
      </g>
    </g>
  </SvgIcon>
);

export default IconDimension;
