import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';
import colors from 'styleguide/styles/colors';

const IconMail = ({
  title = 'Mail',
  color = 'dark',
  viewBoxWidth = 1000,
  viewBoxHeight = 1000,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, color, ...otherProps }}>
    <path
      fill={colors[color]}
      fillRule="nonzero"
      d="M908 167.8H92c-45.2 0-82 36.8-82 82.1v500.4c0 45.3 36.8 82 82 82h816c45.4 0 82-36.7 82-82V249.9c0-45.3-36.5-82.1-82-82.1zm-21.8 59.7l-323.6 287c-28.2 25.1-49.1 37.7-62.6 37.7s-34.4-12.6-62.6-37.7l-323.6-287h772.4zM72 730.2V281.7l252.1 221.1L72 730.2zm44.2 42.6l253.5-225.2 41.5 36.4c24.1 21.1 53.8 31.9 88.8 31.9 35.2 0 64.7-10.8 88.9-31.9l41.5-36.4 253.5 225.2H116.2zm811.9-42.6L675.9 502.8 928 281.7v448.5z"
    />
  </SvgIcon>
);

export default IconMail;
