import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconGraphicDesign = ({
  title = 'IconGraphicDesign',
  viewBoxWidth = 512,
  viewBoxHeight = 512,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, ...otherProps }}>
    <g data-name="Degital grahpic 3" fill="#00AEEF">
      <path d="M451.5 57.88h-400a30 30 0 0 0-30 30v252a18 18 0 0 0 18 18h172v27.3a50.67 50.67 0 0 1-22.91 42.46h-.07a14.27 14.27 0 0 0 7.81 26.2h107.8a14 14 0 0 0 7.59-25.78h-.05a52.23 52.23 0 0 1-24.17-44.18v-26h115.13a6 6 0 0 0 0-12H39.5a6 6 0 0 1-6-6v-252a18 18 0 0 1 18-18h400a18 18 0 0 1 18 18v252a6 6 0 0 1-6 6H427a6 6 0 0 0 0 12h36.5a18 18 0 0 0 18-18v-252a30 30 0 0 0-30-30Zm-176 300v26a64.17 64.17 0 0 0 29.67 54.26 2 2 0 0 1-1.08 3.7H196.33a2.27 2.27 0 0 1-2.16-1.62 2.29 2.29 0 0 1 .92-2.54 62.6 62.6 0 0 0 28.4-52.53v-27.3Z" />
      <path d="M323.94 95.62a17.89 17.89 0 0 0-6.73-4.23 17.79 17.79 0 0 0-11.93 0 17.89 17.89 0 0 0-6.73 4.23l-38.56 38.56a17.89 17.89 0 0 0-4.35 18.42l6.36 19.28 11.39-3.8-6.39-19.27a6 6 0 0 1 1.45-6.14l36.81-36.81v45.87a19.07 19.07 0 1 0 12 0v-45.87L354 142.67a6 6 0 0 1 1.45 6.14L333 216.11h-43.62l-10.5-31.53a6 6 0 1 0-11.39 3.79l9.8 29.4a19.07 19.07 0 0 0 7.77 36.49h.54v26.12a18 18 0 0 0 18 18h15.22a18 18 0 0 0 18-18v-26.12h.53a19.07 19.07 0 0 0 7.77-36.49l21.73-65.17a17.91 17.91 0 0 0-4.35-18.42Zm-12.73 81.27a7.08 7.08 0 1 1 7.07-7.07 7.08 7.08 0 0 1-7.07 7.07Zm13.61 103.49a6 6 0 0 1-6 6H303.6a6 6 0 0 1-6-6v-26.12h27.22Zm19.61-45.2a7.08 7.08 0 0 1-7.08 7.08h-52.29a7.08 7.08 0 0 1 0-14.15h52.29a7.07 7.07 0 0 1 7.08 7.07ZM228.92 117.29a22 22 0 1 0-28 21.16v32.84h-10a6 6 0 0 0-6 6v9.86a88.81 88.81 0 0 0-88 88.81v9.33a6 6 0 0 0 12 0V276a76.78 76.78 0 0 1 76-76.8v10.13a6 6 0 0 0 6 6h10v35.43a22 22 0 1 0 12 0v-35.47h10a6 6 0 0 0 6-6v-32a6 6 0 0 0-6-6h-10v-32.84a22 22 0 0 0 16-21.16Zm-12 154.59a10 10 0 1 1-10-10 10 10 0 0 1 10 10Zm0-68.59h-20v-20h20Zm-20-86a10 10 0 1 1 10 10 10 10 0 0 1-10-10Z" />
      <path d="M465.5 325.88v-234a18 18 0 0 0-18-18h-392a18 18 0 0 0-18 18v234h428Zm-416-12v-222a6 6 0 0 1 6-6h392a6 6 0 0 1 6 6v222Z" />
    </g>
  </SvgIcon>
);

export default IconGraphicDesign;
