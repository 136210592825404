import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconNoCoin = ({
  title = 'IconNoCoin',
  viewBoxWidth = 52,
  viewBoxHeight = 52,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, ...otherProps }} fill="none">
    <path
      d="M5.12301 5.748C3.43258 7.5899 2.49632 9.99997 2.50001 12.5C2.50001 18.023 6.97701 22.5 12.5 22.5C14.9365 22.5037 17.2899 21.6143 19.115 20M21.802 16.178C22.2647 15.007 22.5016 13.7591 22.5 12.5C22.5 6.977 18.023 2.5 12.5 2.5C11.269 2.5 10.09 2.723 9.00001 3.13"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.5 15.5C10.144 16.36 11.343 16.85 12.5 16.891M12.5 16.891C13.614 16.931 14.69 16.555 15.197 15.693M12.5 16.891V19M10 10C10 11.181 10.852 11.665 11.886 12M15.5 9C14.815 8.315 13.609 7.839 12.5 7.809V6M3.5 3.5L21.5 21.5"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default IconNoCoin;
