import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconStability = ({
  title = 'IconStability',
  viewBoxWidth = 52,
  viewBoxHeight = 52,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, ...otherProps }} fill="none">
    <path
      d="M28 16V4.8C28 4.58783 27.9157 4.38434 27.7657 4.23431C27.6157 4.08429 27.4122 4 27.2 4H16M28 16H16M28 16V27.2C28 27.4122 27.9157 27.6157 27.7657 27.7657C27.6157 27.9157 27.4122 28 27.2 28H16M16 16V4M16 16V28M16 16H4M16 4H4.8C4.58783 4 4.38434 4.08429 4.23431 4.23431C4.08429 4.38434 4 4.58783 4 4.8V16M16 28H4.8C4.58783 28 4.38434 27.9157 4.23431 27.7657C4.08429 27.6157 4 27.4122 4 27.2V16"
      stroke="#323232"
      strokeWidth="2.1"
    />
  </SvgIcon>
);

export default IconStability;
