import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconSatisfactionGuarantee = ({
  title = 'IconSatisfactionGuarantee',
  viewBoxWidth = 52,
  viewBoxHeight = 52,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, ...otherProps }}>
    <path
      fill="#00AEEF"
      d="M34.649 16.014A12.127 12.127 0 1 0 22.522 28.14a12.14 12.14 0 0 0 12.127-12.127Zm-22.522 0a10.394 10.394 0 1 1 10.395 10.395 10.408 10.408 0 0 1-10.395-10.395ZM0 10.817a.866.866 0 0 0 .866.867h3.465a.866.866 0 0 0 0-1.733H.866a.866.866 0 0 0-.866.866Zm44.177-.866h-3.465a.866.866 0 0 0 0 1.733h3.465a.866.866 0 0 0 0-1.733ZM4.331 8.219a.866.866 0 0 0 .613-1.48L3.21 5.008a.866.866 0 1 0-1.225 1.225L3.72 7.965a.866.866 0 0 0 .612.254Zm36.381 0a.87.87 0 0 0 .613-.254l1.732-1.733a.866.866 0 1 0-1.225-1.225L40.1 6.74a.866.866 0 0 0 .612 1.479Zm1.12 8.407a.866.866 0 0 0 1.225-1.225l-1.732-1.732a.866.866 0 0 0-1.225 1.225l1.732 1.732ZM3.718 13.669 1.986 15.4a.866.866 0 1 0 1.225 1.225l1.732-1.732a.866.866 0 0 0-1.225-1.225Z"
    />
    <path
      fill="#00AEEF"
      d="M26.438 22.837a.866.866 0 0 0 1.27-.902l-.793-4.758 3.15-3.15a.866.866 0 0 0-.47-1.466l-4.717-.787-1.551-3.878a.866.866 0 0 0-1.61 0l-1.551 3.878-4.717.787a.866.866 0 0 0-.47 1.466l3.15 3.15-.793 4.758a.866.866 0 0 0 1.27.902l3.916-2.14 3.916 2.14Zm-7.103-2.374.574-3.442a.866.866 0 0 0-.242-.754l-2.275-2.275 3.538-.59a.866.866 0 0 0 .661-.532l.93-2.32.928 2.32a.866.866 0 0 0 .662.533l3.538.59-2.275 2.274a.867.867 0 0 0-.242.755l.574 3.441-2.772-1.514a.866.866 0 0 0-.83 0l-2.769 1.514Zm-11.539 5.08a2.599 2.599 0 0 1-2.599-2.599.866.866 0 0 0-1.732 0 2.599 2.599 0 0 1-2.599 2.599.866.866 0 0 0 0 1.732 2.6 2.6 0 0 1 2.599 2.599.866.866 0 0 0 1.732 0 2.599 2.599 0 0 1 2.599-2.599.866.866 0 0 0 0-1.732Zm-3.465 1.76a4.343 4.343 0 0 0-.893-.894c.34-.252.64-.553.893-.893.253.34.553.64.893.893-.34.253-.64.554-.893.893Zm39.846-1.76a2.599 2.599 0 0 1-2.598-2.599.866.866 0 0 0-1.733 0 2.599 2.599 0 0 1-2.598 2.599.866.866 0 1 0 0 1.732 2.599 2.599 0 0 1 2.598 2.599.866.866 0 0 0 1.733 0 2.599 2.599 0 0 1 2.598-2.599.866.866 0 0 0 0-1.732Zm-3.465 1.76a4.346 4.346 0 0 0-.893-.894c.34-.252.64-.553.893-.893.253.34.554.64.893.893a4.34 4.34 0 0 0-.893.893Z"
    />
    <path
      fill="#00AEEF"
      d="M30.674 52.23a.867.867 0 0 0 1.376-.7V28.327a15.592 15.592 0 1 0-19.057 0V51.53a.866.866 0 0 0 1.376.7l7.943-5.797c.02-.013.123-.086.143-.098a.129.129 0 0 1 .132 0c.02.01.123.086.143.098l7.944 5.797Zm-8.864-7.514a1.98 1.98 0 0 0-.554.346l-1.26.92-.073.052v-8.497c.375-.215.72-.477 1.029-.78l.661-.66c.365-.375.67-.803.91-1.27.237.468.544.897.909 1.273l.66.66c.309.303.654.565 1.029.78v8.494l-.072-.052-1.26-.92a1.98 1.98 0 0 0-.555-.346 1.837 1.837 0 0 0-1.424 0Zm3.508-9.182-.661-.661a5.454 5.454 0 0 1-1.269-3.267c1.2.052 2.348.497 3.269 1.268l.66.661a5.46 5.46 0 0 1 1.269 3.269 5.455 5.455 0 0 1-3.269-1.268v-.002Zm-3.662-3.928a5.456 5.456 0 0 1-1.268 3.269l-.661.66a5.455 5.455 0 0 1-3.268 1.269 5.455 5.455 0 0 1 1.268-3.269l.66-.66a5.456 5.456 0 0 1 3.269-1.269Zm8.662 18.219-3.465-2.53V38.26c.564.166 1.146.26 1.733.277h.866a.866.866 0 0 0 .866-.866v12.155Zm0-13.021a7.124 7.124 0 0 0-1.776-4.494l-.66-.66a4.999 4.999 0 0 0-.91-.7c1.17-.35 2.292-.839 3.346-1.454v7.308ZM8.663 16.014a13.861 13.861 0 0 1 23.66-9.8 13.861 13.861 0 0 1-9.8 23.66 13.875 13.875 0 0 1-13.86-13.86Zm9.409 14.937a5.001 5.001 0 0 0-.91.699l-.66.66a7.126 7.126 0 0 0-1.776 4.494v-7.307a15.435 15.435 0 0 0 3.345 1.454Zm-3.346 6.72a.866.866 0 0 0 .866.865h.867a6.852 6.852 0 0 0 1.732-.277v9.038l-3.465 2.528V37.67Z"
    />
  </SvgIcon>
);

export default IconSatisfactionGuarantee;
