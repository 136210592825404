import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconProgramming = ({
  title = 'IconProgramming',
  viewBoxWidth = 52,
  viewBoxHeight = 52,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, ...otherProps }} fill="none">
    <path
      d="M22.6673 19.9948H16.0007M9.33398 13.3281L9.64598 13.5881C11.3553 15.0121 12.21 15.7241 12.21 16.6615C12.21 17.5988 11.3567 18.3108 9.64598 19.7348L9.33398 19.9948"
      stroke="#323232"
      strokeWidth="2.1"
      strokeLinecap="round"
    />
    <path
      d="M29.3337 15.9974C29.3337 22.2827 29.3337 25.4254 27.3803 27.3774C25.4297 29.3307 22.2857 29.3307 16.0003 29.3307C9.71499 29.3307 6.57233 29.3307 4.61899 27.3774C2.66699 25.4267 2.66699 22.2827 2.66699 15.9974C2.66699 9.71206 2.66699 6.5694 4.61899 4.61606C6.57366 2.66406 9.71499 2.66406 16.0003 2.66406C22.2857 2.66406 25.4283 2.66406 27.3803 4.61606C28.679 5.91473 29.1137 7.74006 29.2603 10.6641"
      stroke="#323232"
      strokeWidth="2.1"
      strokeLinecap="round"
    />
  </SvgIcon>
);

export default IconProgramming;
