import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconArrowFull = ({
  title = 'ArrowFull',
  color = 'dark',
  viewBoxWidth = 110,
  viewBoxHeight = 43,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, color, ...otherProps }}>
    <path fill="#fff" d="M0 .837h110v42H0z" />
    <path
      d="M5 20a2 2 0 1 0 0 4v-4Zm101.414 3.414a2 2 0 0 0 0-2.828L93.686 7.858a2 2 0 1 0-2.828 2.828L102.172 22 90.858 33.314a2 2 0 1 0 2.828 2.828l12.728-12.728ZM5 24h100v-4H5v4Z"
      fill="#323232"
    />
  </SvgIcon>
);

export default IconArrowFull;
