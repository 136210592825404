import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';
import colors from 'styleguide/styles/colors';

const IconStar = ({
  title = 'Star',
  color = 'red',
  viewBoxWidth = 15,
  viewBoxHeight = 15,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, color, ...otherProps }}>
    <path
      fill={colors[color]}
      d="M15.111 6.46a.987.987 0 0 0-.573-1.777l-4-.151a.102.102 0 0 1-.09-.067L9.068.732a.987.987 0 0 0-1.854 0L5.836 4.479a.102.102 0 0 1-.09.066l-4 .151a.987.987 0 0 0-.573 1.778l3.138 2.467a.102.102 0 0 1 .036.106l-1.08 3.827a.987.987 0 0 0 1.502 1.089l3.315-2.222a.098.098 0 0 1 .112 0l3.315 2.222a.978.978 0 0 0 1.134 0 .978.978 0 0 0 .368-1.067l-1.088-3.84a.097.097 0 0 1 .035-.106l3.151-2.49Z"
    />
  </SvgIcon>
);

export default IconStar;
