import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconApproveDesign = ({
  title = 'ApproveDesign',
  color = 'none',
  viewBoxWidth = 64,
  viewBoxHeight = 64,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, color, ...otherProps }}>
    <path
      d="M10.6962 55.5849C10.6962 57.002 9.54738 58.1508 8.13032 58.1508C6.71326 58.1508 5.56445 57.002 5.56445 55.5849C5.56445 54.1678 6.71326 53.019 8.13032 53.019C9.54738 53.019 10.6962 54.1678 10.6962 55.5849Z"
      fill="#FCEBEE"
    />
    <path
      d="M6.5012 30.771C7.57981 41.6805 15.0317 50.3481 25.5775 50.9317C34.0214 51.3989 42.9895 50.1065 50.1537 45.4243C56.6459 41.1813 62.5958 32.4516 59.8376 23.6668C58.3891 19.0537 54.4964 13.2893 49.3891 13.6149C44.0482 13.9554 40.5876 19.0433 35.2816 19.3712C28.637 19.7819 4.55799 11.1174 6.5012 30.771Z"
      fill="#FCEBEE"
    />
    <path
      d="M16.4617 35.747C16.4617 30.1928 16.4617 20.7126 16.4617 20.7126C16.4617 19.3008 17.6062 18.1562 19.0181 18.1562H45.2976C46.7095 18.1562 47.854 19.3008 47.854 20.7126V39.6752C47.854 41.0871 46.7095 42.2316 45.2976 42.2316H19.0181C18.3077 42.2316 17.6649 41.9418 17.2016 41.4741C16.7442 41.0121 16.4617 40.3767 16.4617 39.6752C16.4617 39.6752 16.4617 38.048 16.4617 35.747Z"
      stroke="#333333"
      strokeWidth="0.466667"
      strokeMiterlimit="10"
    />
    <mask id="mask0_3369_21632" maskUnits="userSpaceOnUse" x="26" y="29" width="34" height="11">
      <path d="M59.4964 29.6475V39.3067H26.0742V29.6475H59.4964Z" fill="#FF5678" />
    </mask>
    <g mask="url(#mask0_3369_21632)">
      <path
        d="M56.0874 33.9177H37.0126C36.6597 33.9177 36.3735 33.6315 36.3735 33.2785V31.1289C36.3735 30.7759 36.6597 30.4897 37.0126 30.4897H56.0874C56.4403 30.4897 56.7265 30.7759 56.7265 31.1289V33.2785C56.7265 33.6315 56.4403 33.9177 56.0874 33.9177Z"
        fill="#4779F9"
      />
      <mask id="mask1_3369_21632" maskUnits="userSpaceOnUse" x="36" y="30" width="21" height="4">
        <path
          d="M56.0874 33.9177H37.0126C36.6597 33.9177 36.3735 33.6315 36.3735 33.2785V31.1289C36.3735 30.7759 36.6597 30.4897 37.0126 30.4897H56.0874C56.4403 30.4897 56.7265 30.7759 56.7265 31.1289V33.2785C56.7265 33.6315 56.4403 33.9177 56.0874 33.9177Z"
          fill="#4779F9"
        />
      </mask>
      <g mask="url(#mask1_3369_21632)">
        <path
          d="M38.771 8.70703C38.5034 10.3048 38.4326 16.6747 38.5615 18.2897C38.6062 18.8504 38.6776 19.4195 38.9081 19.9326C39.1386 20.4457 39.5514 20.9021 40.0902 21.0636C40.6291 21.2251 41.2889 21.0248 41.5535 20.5285C41.8182 20.0321 41.5332 19.2966 40.9791 19.1994C40.2808 19.0768 39.7964 19.8575 39.5248 20.5124C39.1504 21.4149 38.7808 22.4686 39.2455 23.328C39.7141 24.1946 40.8837 24.5365 41.2628 25.4457C41.615 26.2905 41.126 27.2542 40.5149 27.9353C39.9038 28.6167 39.1393 29.2005 38.7783 30.0414C38.319 31.1116 38.6198 32.35 39.0452 33.4341C39.4706 34.5182 40.0237 35.5916 40.0634 36.7555C40.132 38.7664 38.6724 40.5067 38.2969 42.4834C37.7849 45.178 39.3191 48.0949 38.2736 50.6306"
          stroke="#E43F5D"
          strokeWidth="0.233333"
          strokeMiterlimit="10"
        />
        <path
          d="M41.5191 28.6695C41.644 28.4263 41.9929 28.2814 42.1999 28.4598C42.4059 28.6372 42.1786 30.3727 42.1446 30.5923C42.1074 30.8336 41.7796 30.9461 41.5665 30.8269C41.3534 30.7076 41.263 30.437 41.2863 30.1939C41.3096 29.9509 41.3941 28.9128 41.5191 28.6695Z"
          stroke="#E43F5D"
          strokeWidth="0.233333"
          strokeMiterlimit="10"
        />
        <path
          d="M43.3901 51.454C42.924 49.6878 44.5348 48.2825 44.5183 46.5552C44.6798 44.4516 42.4438 41.5402 43.3323 39.7264C43.547 39.3883 44.0521 38.9162 44.4806 39.1796C45.4271 39.7954 44.4427 40.9474 43.5749 40.4302C41.4385 38.6242 44.7801 36.2661 45.043 34.3249C45.1995 33.3859 44.9089 32.8037 44.5866 32.0984C44.3152 31.5042 44.3532 30.8119 44.6978 30.257C45.0049 29.7626 45.3978 29.2705 45.537 28.9223C45.9687 27.8416 44.4604 26.2191 44.337 24.9393C44.2475 24.0117 44.5451 23.0882 45.1615 22.3892C46.134 21.2866 48.8417 14.3092 47.1547 10.0674C46.3521 9.04765 44.8733 10.8198 44.0138 9.77103C43.8953 9.59331 43.8953 9.35627 43.8953 9.11923C44.0731 7.81553 44.1916 6.51183 44.4032 5.21191"
          stroke="#E43F5D"
          strokeWidth="0.233333"
          strokeMiterlimit="10"
        />
        <path
          d="M48.6285 9.60498C49.1183 9.86228 49.3851 15.1671 49.4229 15.7191C49.4607 16.2711 49.3048 16.8168 49.1329 17.3427C48.4827 19.3324 47.5684 21.3047 47.6054 23.3977C47.6266 24.5969 47.9635 25.7878 47.8481 26.9816C47.6663 28.8632 46.379 30.5675 46.4983 32.4541C46.5751 33.6685 47.2347 34.7917 47.291 36.0073C47.3526 37.3385 46.6839 38.6284 46.7658 39.9585C46.8268 40.9484 47.2963 41.8593 47.6007 42.8032C48.3134 45.0135 48.0936 47.5084 47.0055 49.5601"
          stroke="#E43F5D"
          strokeWidth="0.233333"
          strokeMiterlimit="10"
        />
        <path
          d="M50.2314 8.64111C51.3928 9.36615 51.3767 15.82 50.9231 17.1117C50.4695 18.4034 49.7093 19.6716 49.8508 21.0334C50.0242 22.7024 51.5327 24.0975 51.3908 25.7695C51.2192 27.7903 48.7367 29.0341 48.5898 31.0569C48.5408 31.7317 48.799 32.4432 49.3331 32.8585C50.0106 33.3853 51.1776 33.2436 51.5402 32.4658C51.9028 31.6879 50.9778 30.6253 50.2155 31.0195C49.6178 31.3285 49.5491 32.1753 49.759 32.8146C49.9689 33.4539 50.3727 34.0355 50.4493 34.704C50.595 35.9769 49.5508 37.0332 49.0115 38.1953C48.0138 40.3448 48.7694 42.8482 49.3522 45.1451C49.935 47.4421 50.2238 50.1824 48.5931 51.9018"
          stroke="#E43F5D"
          strokeWidth="0.233333"
          strokeMiterlimit="10"
        />
        <path
          d="M52.6685 8.5791C54.1463 11.5565 51.172 19.4791 51.7869 22.5308C51.8915 23.6748 53.2687 23.9035 53.1348 22.9124C52.6618 22.258 51.9285 23.0707 52.2555 23.6823C52.9614 24.8642 54.155 25.382 53.1785 26.9836C50.8831 30.7482 53.6891 27.7257 54.3513 29.9007C54.5105 30.4237 54.3906 30.9913 54.1986 31.5032C53.7862 32.6027 53.0582 33.5472 52.4786 34.5686C51.8991 35.5898 51.4559 36.7735 51.6942 37.9234C51.9187 39.0067 52.7129 39.8936 53.0088 40.9596C53.3916 42.3385 52.8962 43.7876 52.486 45.1586C52.0759 46.5296 51.765 48.0825 52.4601 49.3332"
          stroke="#E43F5D"
          strokeWidth="0.233333"
          strokeMiterlimit="10"
        />
      </g>
    </g>
    <path
      d="M55.5265 29.0705H40.9509C40.5979 29.0705 40.3118 28.7843 40.3118 28.4314V26.2817C40.3118 25.9287 40.5979 25.6426 40.9509 25.6426H55.5265C55.8795 25.6426 56.1656 25.9287 56.1656 26.2817V28.4314C56.1656 28.7843 55.8795 29.0705 55.5265 29.0705Z"
      stroke="#4779F9"
      strokeWidth="0.466667"
      strokeMiterlimit="10"
    />
    <path
      d="M17.6934 39.9512L25.3172 31.5367C26.1691 30.5965 27.6411 30.5801 28.5137 31.5012L31.6989 34.8633C32.4588 35.6654 33.6989 35.7718 34.5843 35.1107L36.8069 33.4511C37.5961 32.8619 38.6823 32.8752 39.4567 33.4837L44.825 37.7016"
      stroke="#333333"
      strokeWidth="0.5033"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.9195 24.8401C24.9195 26.3273 23.714 27.5328 22.2269 27.5328C20.7397 27.5328 19.5342 26.3273 19.5342 24.8401C19.5342 23.353 20.7397 22.1475 22.2269 22.1475C23.714 22.1475 24.9195 23.353 24.9195 24.8401Z"
      fill="#EF4129"
    />
    <path
      d="M25.6596 26.2598C25.6596 26.9187 25.1255 27.4528 24.4666 27.4528C23.8078 27.4528 23.2737 26.9187 23.2737 26.2598C23.2737 25.601 23.8078 25.0669 24.4666 25.0669C25.1255 25.0669 25.6596 25.601 25.6596 26.2598Z"
      fill="#4D4D4D"
    />
    <path
      d="M21.6212 42.6647L6.59051 42.8388C5.81403 42.8478 5.17733 42.2257 5.16833 41.4492L5.04241 30.5773C5.03341 29.8008 5.65553 29.1641 6.43201 29.1551L21.4627 28.981C22.2392 28.972 22.8759 29.5941 22.8849 30.3706L23.0108 41.2425C23.0198 42.019 22.3977 42.6557 21.6212 42.6647Z"
      fill="#00AEEF"
    />
    <path
      d="M55.8479 44.8591C55.9118 44.7408 56.0799 44.736 56.1504 44.8505L57.5182 47.0712L58.8861 49.2919C58.9566 49.4064 58.8768 49.5543 58.7423 49.5582L56.1352 49.6324L53.5281 49.7067C53.3937 49.7105 53.3054 49.5673 53.3694 49.449L54.6086 47.154L55.8479 44.8591Z"
      fill="#F7AA17"
    />
    <path
      d="M8.71231 18.6759C8.68122 18.5988 8.74653 18.5171 8.8298 18.5288L10.4442 18.756L12.0585 18.9831C12.1418 18.9948 12.1806 19.0912 12.1284 19.1565L11.1164 20.4235L10.1044 21.6906C10.0522 21.756 9.9481 21.7413 9.91701 21.6643L9.31466 20.1701L8.71231 18.6759Z"
      fill="#00AEEF"
    />
  </SvgIcon>
);

export default IconApproveDesign;
