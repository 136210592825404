import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconAnalytics = ({
  title = 'IconAnalytics',
  viewBoxWidth = 52,
  viewBoxHeight = 52,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, ...otherProps }} fill="none">
    <path
      d="M8.88921 28.4436H24.4448M12.0003 22.2214V28.4436M21.3337 22.2214V28.4436M2.66699 5.11024C2.66699 4.69768 2.83088 4.30202 3.1226 4.0103C3.41433 3.71858 3.80999 3.55469 4.22255 3.55469H29.1114C29.524 3.55469 29.9197 3.71858 30.2114 4.0103C30.5031 4.30202 30.667 4.69768 30.667 5.11024V20.6658C30.667 21.0784 30.5031 21.474 30.2114 21.7657C29.9197 22.0575 29.524 22.2214 29.1114 22.2214H4.22255C3.80999 22.2214 3.41433 22.0575 3.1226 21.7657C2.83088 21.474 2.66699 21.0784 2.66699 20.6658V5.11024Z"
      stroke="#323232"
      strokeWidth="2.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4453 15.9996L15.112 11.3329L18.2231 14.444L22.8898 9.77734"
      stroke="#323232"
      strokeWidth="2.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default IconAnalytics;
