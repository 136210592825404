import * as React from 'react';
import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconFour = ({
  title = 'Four',
  color = 'mediumGray',
  viewBoxWidth = 81,
  viewBoxHeight = 104,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, color, viewBoxWidth, viewBoxHeight, ...otherProps }}>
    <path
      fill="#323232"
      d="M1 84.413a1 1 0 0 1-1-1v-13.64a1 1 0 0 1 .155-.534L43.683.465A1 1 0 0 1 44.528 0h21.83a1 1 0 0 1 1 1v67.978h12.26a1 1 0 0 1 1 1v13.435a1 1 0 0 1-1 1h-12.26v18.231a1 1 0 0 1-1 1H50.443a1 1 0 0 1-1-1V84.413H1Zm48.646-63.158h-.259a1 1 0 0 0-.845.466L19.328 68.014a.628.628 0 0 0 .53.964h29.788V21.255Z"
    />
    <path
      stroke="#323232"
      fill="#323232"
      d="M1 84.413a1 1 0 0 1-1-1v-13.64a1 1 0 0 1 .155-.534L43.683.465A1 1 0 0 1 44.528 0h21.83a1 1 0 0 1 1 1v67.978h12.26a1 1 0 0 1 1 1v13.435a1 1 0 0 1-1 1h-12.26v18.231a1 1 0 0 1-1 1H50.443a1 1 0 0 1-1-1V84.413H1Zm48.646-63.158h-.259a1 1 0 0 0-.845.466L19.328 68.014a.628.628 0 0 0 .53.964h29.788V21.255Z"
    />
  </SvgIcon>
);

export default IconFour;
