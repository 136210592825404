import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconPriceMatch = ({
  title = 'IconPriceMatch',
  viewBoxWidth = 52,
  viewBoxHeight = 52,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, ...otherProps }}>
    <path
      fill="#00AEEF"
      d="M49.32 3.076c-3.575-3.575-9.39-3.573-12.962 0-1.926 1.926-3.044 4.746-3.69 7.562l-5.652-.77-4.962 4.962c-2.2-2.485-5.395-4.063-8.96-4.063-6.614 0-11.996 5.403-11.996 12.043 0 3.562 1.556 6.757 4.012 8.963L0 36.883l15.512 15.512 27.02-27.02-.84-5.653c3.371-.764 5.942-1.998 7.628-3.684 3.574-3.574 3.574-9.39 0-12.963v.001ZM2.887 22.811c0-5.654 4.58-10.254 10.207-10.254 5.627 0 10.206 4.6 10.206 10.254s-4.578 10.254-10.206 10.254c-5.629 0-10.207-4.6-10.207-10.254Zm12.626 27.056L2.53 36.884l4.003-4.002a11.884 11.884 0 0 0 6.561 1.973c6.615 0 11.997-5.403 11.997-12.044a12 12 0 0 0-1.94-6.546l4.504-4.504 4.667.635a38.242 38.242 0 0 0-.467 4.567 2.812 2.812 0 0 0-1.13.68 2.852 2.852 0 0 0 0 4.028 2.838 2.838 0 0 0 2.014.833c.73 0 1.46-.278 2.014-.833a2.832 2.832 0 0 0 .836-2.015 2.806 2.806 0 0 0-1.94-2.687 36.67 36.67 0 0 1 .455-4.331l4.826.657 1.702 11.452-25.12 25.12ZM33.489 18.91c.2.2.31.466.31.748a1.06 1.06 0 0 1-2.12 0 1.057 1.057 0 0 1 1.81-.748Zm7.942-.96-.929-6.245-6.044-.823c.578-2.456 1.544-4.919 3.165-6.54 2.876-2.876 7.557-2.875 10.432 0 2.877 2.876 2.877 7.556 0 10.433-1.424 1.424-3.655 2.488-6.624 3.176Zm-29.577 9.525L6.91 22.529l1.266-1.265 3.677 3.679 6.158-6.161 1.266 1.265-7.424 7.428Z"
    />
  </SvgIcon>
);

export default IconPriceMatch;
