import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconSecurity = ({
  title = 'IconSecurity',
  viewBoxWidth = 52,
  viewBoxHeight = 52,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, ...otherProps }} fill="none">
    <g clipPath="url(#clip0_6337_3160)">
      <path
        d="M16.667 0L4.66699 5.978V20.044C4.66699 23.2266 5.93127 26.2788 8.18171 28.5293C10.4321 30.7797 13.4844 32.044 16.667 32.044C19.8496 32.044 22.9018 30.7797 25.1523 28.5293C27.4027 26.2788 28.667 23.2266 28.667 20.044V5.978L16.667 0ZM23.497 27.314L16.667 23.912V26.14L21.703 28.649C20.1858 29.5327 18.4628 30.0014 16.707 30.0081C14.9513 30.0148 13.2247 29.5592 11.7009 28.6871C10.177 27.815 8.90959 26.5572 8.02595 25.04C7.14232 23.5228 6.67363 21.7997 6.66699 20.044V7.21L16.667 2.228L26.667 7.21V10.96L16.667 5.978V8.206L26.667 13.188V16.938L16.667 11.956V14.184L26.667 19.166V20.044C26.6675 20.9524 26.543 21.8565 26.297 22.731L16.667 17.934V20.162L25.562 24.593C25.0344 25.6115 24.3359 26.5318 23.497 27.314Z"
        fill="#323232"
      />
    </g>
    <defs>
      <clipPath id="clip0_6337_3160">
        <rect width="32" height="32" fill="white" transform="translate(0.666992)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default IconSecurity;
