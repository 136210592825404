import * as React from 'react';
import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconOne = ({
  title = 'One',
  color = 'mediumGray',
  viewBoxWidth = 46,
  viewBoxHeight = 106,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, color, viewBoxWidth, viewBoxHeight, ...otherProps }}>
    <path
      fill="#323232"
      d="M44.201.672a1 1 0 0 1 1 1V105a1 1 0 0 1-1 1h-17.08a1 1 0 0 1-1-1V19.716a.478.478 0 0 0-.735-.403L2.407 33.993a1 1 0 0 1-1.538-.843V18.036a1 1 0 0 1 .46-.842L26.798.831a1 1 0 0 1 .54-.16H44.2Z"
    />
  </SvgIcon>
);

export default IconOne;
