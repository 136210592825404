import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconBleeds = ({ title = 'Bleeds', viewBoxWidth = 48, viewBoxHeight = 48, ...otherProps }: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, ...otherProps }}>
    <g fill="none" fillRule="evenodd">
      <path fill="#CCEFFC" d="M20 20h28v28H20z" />
      <path stroke="#323232" d="M.5.5h44v44H.5z" />
    </g>
  </SvgIcon>
);

export default IconBleeds;
