import * as React from 'react';
import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';

const IconTwo = ({
  title = 'Two',
  color = 'mediumGray',
  viewBoxWidth = 69,
  viewBoxHeight = 102,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, color, viewBoxWidth, viewBoxHeight, ...otherProps }}>
    <path
      fill="#323232"
      d="M1.585 101.161a1 1 0 0 1-1-1v-11.64a1 1 0 0 1 .3-.713l34.346-33.67c3.313-3.346 6.075-6.319 8.284-8.918 2.209-2.599 3.866-5.116 4.97-7.553 1.105-2.436 1.657-5.035 1.657-7.796 0-3.151-.715-5.848-2.144-8.09-1.43-2.273-3.395-4.027-5.896-5.262-2.502-1.234-5.344-1.852-8.528-1.852-3.28 0-6.156.683-8.625 2.047-2.469 1.332-4.385 3.232-5.75 5.701-1.2 2.225-1.86 4.831-1.978 7.82-.022.552-.467 1-1.02 1H1a.982.982 0 0 1-.988-1C.157 24.319 1.6 19.146 4.337 14.716c2.891-4.678 6.87-8.3 11.938-10.866C21.375 1.283 27.223 0 33.818 0c6.692 0 12.572 1.25 17.64 3.752 5.067 2.502 8.998 5.929 11.792 10.282 2.826 4.353 4.24 9.323 4.24 14.91 0 3.737-.715 7.408-2.145 11.014-1.429 3.606-3.947 7.601-7.553 11.987-3.573 4.386-8.592 9.697-15.057 15.934L25.673 85.232a.484.484 0 0 0 .345.823H68a1 1 0 0 1 1 1v13.106a1 1 0 0 1-1 1H1.585Z"
    />
    <path
      stroke="#323232"
      fill="#323232"
      d="M1.585 101.161a1 1 0 0 1-1-1v-11.64a1 1 0 0 1 .3-.713l34.346-33.67c3.313-3.346 6.075-6.319 8.284-8.918 2.209-2.599 3.866-5.116 4.97-7.553 1.105-2.436 1.657-5.035 1.657-7.796 0-3.151-.715-5.848-2.144-8.09-1.43-2.273-3.395-4.027-5.896-5.262-2.502-1.234-5.344-1.852-8.528-1.852-3.28 0-6.156.683-8.625 2.047-2.469 1.332-4.385 3.232-5.75 5.701-1.2 2.225-1.86 4.831-1.978 7.82-.022.552-.467 1-1.02 1H1a.982.982 0 0 1-.988-1C.157 24.319 1.6 19.146 4.337 14.716c2.891-4.678 6.87-8.3 11.938-10.866C21.375 1.283 27.223 0 33.818 0c6.692 0 12.572 1.25 17.64 3.752 5.067 2.502 8.998 5.929 11.792 10.282 2.826 4.353 4.24 9.323 4.24 14.91 0 3.737-.715 7.408-2.145 11.014-1.429 3.606-3.947 7.601-7.553 11.987-3.573 4.386-8.592 9.697-15.057 15.934L25.673 85.232a.484.484 0 0 0 .345.823H68a1 1 0 0 1 1 1v13.106a1 1 0 0 1-1 1H1.585Z"
    />
  </SvgIcon>
);

export default IconTwo;
